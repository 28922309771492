import * as amplitude from "@amplitude/analytics-browser";
import * as sessionReplay from "@amplitude/session-replay-browser";
import Plausible from "plausible-tracker";

import "./src/styles/global.css";

export const onClientEntry = () => {
  amplitude.init("3e79c552f116f9368ac8efeabd653ea1", {
    autocapture: { elementInteractions: false },
  });
  sessionReplay.init("3e79c552f116f9368ac8efeabd653ea1", {
    sampleRate: 0.5,
  });
  const plausible = Plausible({
    domain: "fullstackextensions.com",
  });
  plausible.trackPageview();
};
